.relative[data-v-f9604b1c] {
  position: relative;
}
.w-full[data-v-f9604b1c] {
  width: 100%;
}
.relative-center[data-v-f9604b1c] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-f9604b1c] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-f9604b1c] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-f9604b1c] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-f9604b1c] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-f9604b1c] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-f9604b1c] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-f9604b1c] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-f9604b1c] {
  justify-content: space-between;
}
.flex[data-v-f9604b1c] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-f9604b1c] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-f9604b1c],
.card-list-no-padding .ci-description[data-v-f9604b1c] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-f9604b1c] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.card-list[data-v-f9604b1c] {
  display: grid;
}
.card-list.styled-2[data-v-f9604b1c] {
  grid-template-columns: repeat(2, 1fr);
}
.card-list.styled-3[data-v-f9604b1c] {
  grid-template-columns: repeat(3, 1fr);
}
.card-list.styled-4[data-v-f9604b1c] {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.card-list.styled-5[data-v-f9604b1c] {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, auto);
}
.card-list.styled-5>div[data-v-f9604b1c]:nth-child(1),
.card-list.styled-5>div[data-v-f9604b1c]:nth-child(2),
.card-list.styled-5>div[data-v-f9604b1c]:nth-child(3) {
  grid-column: span 2;
}
.card-list.styled-5>div[data-v-f9604b1c]:nth-child(4),
.card-list.styled-5>div[data-v-f9604b1c]:nth-child(5) {
  grid-column: span 3;
}
.card-list.styled-6[data-v-f9604b1c] {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
}