.hover-menu {
  background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
  border-radius: 0.04166667rem 0 0 0.04166667rem;
  position: fixed;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
}
.hover-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 0.41666667rem;
  height: 0.44791667rem;
  color: white;
  cursor: pointer;
  position: relative;
}
.hover-menu-item:hover {
  background: rgba(191, 222, 255, 0.6);
  border-radius: 0.04166667rem 0 0 0.04166667rem;
}
.hover-menu-txt {
  font-size: 0.0625rem;
  margin-top: 0.04166667rem;
}
.hover-menu-wechat:hover .hover-menu-wechat-hide {
  display: block;
}
.hover-menu-wechat-hide {
  display: none;
  position: absolute;
  width: 200%;
  right: 100%;
  top: 0;
  box-shadow: 0 0 0.05208333rem 0.01041667rem #ccc;
}
.hover-menu-wechat-qr {
  width: 100%;
}
.hover-menu-phone:hover .hover-menu-phone-hide {
  display: flex;
}
.hover-menu-phone-hide {
  background: white;
  display: none;
  font-size: 0.1875rem;
  color: #3295f0;
  position: absolute;
  right: 100%;
  height: 100%;
  top: 0;
  box-shadow: 0 0 0.05208333rem 0.01041667rem #ccc;
  white-space: nowrap;
  align-items: center;
  padding: 0 0.10416667rem;
}