.relative[data-v-050649a5] {
  position: relative;
}
.w-full[data-v-050649a5] {
  width: 100%;
}
.relative-center[data-v-050649a5] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-050649a5] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-050649a5] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-050649a5] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-050649a5] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-050649a5] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-050649a5] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-050649a5] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-050649a5] {
  justify-content: space-between;
}
.flex[data-v-050649a5] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-050649a5] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-050649a5],
.card-list-no-padding .ci-description[data-v-050649a5] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-050649a5] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.about[data-v-050649a5] .now-button {
  display: none;
}
.about .link-card[data-v-050649a5] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about .link-card .lc-item[data-v-050649a5] {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.08333333rem;
  width: 1.04166667rem;
  height: 0.51041667rem;
  background: rgba(0,0,0,0);
  box-shadow: 0.015625rem 0.015625rem 0.04166667rem 0.00520833rem #e8e8e8;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #333;
  cursor: pointer;
}
.about .link-card .lc-item[data-v-050649a5]:hover,
.about .link-card .lc-item.checked[data-v-050649a5] {
  background: #4e99fd;
  color: #fff;
}
.about .banner-title[data-v-050649a5] {
  color: #3384e8;
  font-size: 0.25rem;
  font-weight: bold;
  margin-bottom: 0.33333333rem;
  text-align: left;
  text-align: center;
}
@media screen and (max-width: 767px) {
.about .banner-title[data-v-050649a5] {
    text-align: center;
    font-size: 0.125rem;
}
}
.about .banner-subtitle[data-v-050649a5] {
  color: #fff;
  font-size: 0.125rem;
  margin-bottom: 0.33333333rem;
  text-align: left;
  line-height: 2;
  font-family: "Source Han Sans CN-Medium",serif;
}
@media screen and (max-width: 767px) {
.about .banner-subtitle[data-v-050649a5] {
    text-align: center;
    font-size: 0.09375rem;
    white-space: normal;
    word-wrap: break-word;
}
}
.about .v-list[data-v-050649a5] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.22395833rem;
}
.about .v-list .v-item[data-v-050649a5] {
  display: grid;
  padding: 0.20833333rem 0.125rem;
  justify-content: center;
  grid-template-rows: repeat(3, auto) 1fr;
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  border: 0.00520833rem solid rgba(51,51,51,.2);
}
.about .v-list .v-item[data-v-050649a5]:hover {
  background: linear-gradient(167deg, #68A9FF 0%, #4E99FD 100%);
}
.about .v-list .v-item:hover .sp-icon[data-v-050649a5] {
  color: #fff;
}
.about .v-list .v-item:hover .sp-icon[data-v-050649a5]:before {
  border: 0.00520833rem solid #fff;
}
.about .v-list .v-item:hover .sp-tt[data-v-050649a5],
.about .v-list .v-item:hover .sp-description[data-v-050649a5] {
  color: #fff;
}
.about .v-list .v-item:hover .sp-sub[data-v-050649a5] {
  color: hsla(0,0%,100%,.6);
}
.about .v-list .v-item .sp-icon[data-v-050649a5] {
  width: 0.52083333rem;
  height: 0.52083333rem;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4e99fd;
}
.about .v-list .v-item .sp-icon[data-v-050649a5]:before {
  content: "";
  width: 0.36828478rem;
  height: 0.36828478rem;
  position: absolute;
  transform: rotate(45deg);
  border: 0.00520833rem solid rgba(51,51,51,.1);
}
.about .v-list .v-item .sp-tt[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #333;
  text-align: center;
  margin-top: 0.08333333rem;
}
.about .v-list .v-item .sp-sub[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.09375rem;
  color: #999;
  text-align: center;
  margin-top: 0.05208333rem;
}
.about .v-list .v-item .sp-description[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #666;
  line-height: 0.16666667rem;
  margin-top: 0.125rem;
}
.about .bbg[data-v-050649a5] {
  background: url("@/asset/about/b3-bg.png") center no-repeat;
  background-size: cover;
}
.about .bt[data-v-050649a5] {
  text-align: center;
}
.about .bt .title[data-v-050649a5] {
  font-family: YouSheBiaoTiHei,YouSheBiaoTiHei;
  font-weight: 400;
  font-size: 0.1875rem;
  color: #fff;
}
.about .bt .en[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: hsla(0,0%,100%,.4);
}
.about .bt .sub[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: hsla(0,0%,100%,.6);
}
.about .zig-card[data-v-050649a5] {
  display: flex;
  align-items: center;
}
.about .zig-card .word-context[data-v-050649a5] {
  width: 3.64583333rem;
  flex-shrink: 0;
  height: 1.89583333rem;
  box-sizing: border-box;
  background: #fff;
  padding: 0.3125rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transform: translateX(-0.3125rem);
  box-shadow: 0.015625rem 0.02604167rem 0.10416667rem 0.00520833rem rgba(202,202,202,.16);
  border-radius: 0.02083333rem 0.02083333rem 0.02083333rem 0.02083333rem;
}
.about .zig-card .word-context .wt[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bold;
  font-size: 0.125rem;
  color: #4e99fd;
}
.about .zig-card .word-context .word[data-v-050649a5] {
  margin-top: 0.20833333rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #666;
  line-height: 0.16666667rem;
  white-space: nowrap;
}
.about .s-list[data-v-050649a5] {
  display: flex;
  justify-content: space-between;
}
.about .s-list .s-item[data-v-050649a5] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #333;
  line-height: 0.1875rem;
  text-stroke: 0.00520833rem rgba(0,0,0,0);
  text-align: center;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 0.00520833rem rgba(0,0,0,0);
}
.about .s-list .s-item .title[data-v-050649a5] {
  margin-top: 0.20833333rem;
}
.about .b4[data-v-050649a5] {
  background: url("@/asset/about/b4.png") center no-repeat;
  background-size: cover;
  height: 2.10416667rem;
  position: relative;
  margin-top: 0.75520833rem;
}
.about .b4[data-v-050649a5]:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-0.44270833rem);
  width: 3.640625rem;
  height: 2.859375rem;
  background: url("@/asset/about/b4-0.png") center no-repeat;
  background-size: cover;
}
.about .b4 .dot-list[data-v-050649a5] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  height: 0.9375rem;
  justify-content: space-between;
}
.about .b4 .dot-list .dl-item[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.1875rem;
  color: #0865aa;
  position: relative;
  padding-left: 0.15625rem;
}
.about .b4 .dot-list .dl-item[data-v-050649a5]:before {
  content: "";
  width: 0.10416667rem;
  height: 0.10416667rem;
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #0865aa;
}
.about .block-info[data-v-050649a5] {
  background: #fff;
  box-shadow: 0.015625rem 0.02604167rem 0.10416667rem 0.00520833rem #e0e0e0;
  box-sizing: border-box;
  padding: 0.3125rem;
}
.about .block-info .title[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #333;
  line-height: 0.1875rem;
  text-stroke: 0.00520833rem rgba(0,0,0,0);
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 0.00520833rem rgba(0,0,0,0);
}
.about .block-info .if-list[data-v-050649a5] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.72916667rem;
  margin-top: 0.20833333rem;
}
.about .block-info .if-list .if-item[data-v-050649a5] {
  display: flex;
  flex-direction: column;
  gap: 0.05208333rem;
}
.about .block-info .if-list .if-item .if-tt[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-size: 0.07291667rem;
  color: #333;
}
.about .block-info .if-list .if-item .if-val[data-v-050649a5] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-size: 0.125rem;
  color: #333;
  line-height: 1.5;
}
.about .block-info .if-list .if-item .if-val .sm[data-v-050649a5] {
  font-size: 0.08333333rem;
}
.about .map-container[data-v-050649a5] {
  height: 2.04166667rem;
  margin-top: 0.20833333rem;
  background: url("@/asset/about/a-map.png") center no-repeat;
  background-size: cover;
}
.about .sub-container[data-v-050649a5] {
  display: flex;
  justify-content: space-between;
}
.about .sub-container select[data-v-050649a5] {
  height: 0.20833333rem;
  padding: 0.05208333rem;
  border-radius: 0.02083333rem;
  background-color: #f2f2f2;
  font-size: 0.07291667rem;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}
.about .sub-container select[data-v-050649a5]:hover {
  background-color: #e0e0e0;
}
.about .sub-container select[data-v-050649a5]::-ms-expand {
  display: none;
}
.about .sub-container select[data-v-050649a5]::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 0.05208333rem;
  transform: translateY(-50%);
  color: #999;
  pointer-events: none;
}
.about .n-i[data-v-050649a5] {
  width: 1.16666667rem;
  height: 0.3125rem;
  position: relative;
}
.about .n-i input[data-v-050649a5] {
  outline: none;
  border: 0.00520833rem solid #ddd;
  width: 100%;
  height: 100%;
  padding-left: 0.125rem;
  box-sizing: border-box;
}
.about .n-i.required[data-v-050649a5]:before {
  content: "*";
  display: block;
  position: absolute;
  width: 0.05208333rem;
  height: 0.05208333rem;
  left: 0.0625rem;
  top: 50%;
  transform: translateY(calc(-50% - 0.03125rem));
  font-size: 0.125rem;
  color: red;
}
.about .n-btn[data-v-050649a5] {
  width: 1.16666667rem;
  height: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4e99fd;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.08333333rem;
  color: #fff;
  cursor: pointer;
}
.about .n-btn span[data-v-050649a5] {
  display: block;
  width: 0.20833333rem;
  text-align: justify;
  text-align-last: justify;
}