.relative[data-v-fa0cdada] {
  position: relative;
}
.w-full[data-v-fa0cdada] {
  width: 100%;
}
.relative-center[data-v-fa0cdada] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-fa0cdada] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-fa0cdada] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-fa0cdada] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-fa0cdada] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-fa0cdada] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-fa0cdada] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-fa0cdada] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-fa0cdada] {
  justify-content: space-between;
}
.flex[data-v-fa0cdada] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-fa0cdada] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-fa0cdada],
.card-list-no-padding .ci-description[data-v-fa0cdada] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-fa0cdada] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.structure[data-v-fa0cdada] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.structure .card-box[data-v-fa0cdada] {
  margin-bottom: 0.41666667rem;
}
.structure .img[data-v-fa0cdada] {
  width: 100%;
  display: block;
  margin-top: 0.20833333rem;
}
.advantage[data-v-fa0cdada] {
  height: 3.97916667rem;
  background: url(@/asset/smart-port/ic_port_10.png) no-repeat center/cover;
  text-align: center;
  overflow: hidden;
}
.advantage .en[data-v-fa0cdada] {
  font-size: 0.1875rem;
  color: hsla(0,0%,100%,.4);
  margin-top: 0.41666667rem;
}
.advantage .title[data-v-fa0cdada] {
  font-family: YouSheBiaoTiHei;
  margin-top: 0.0625rem;
  font-size: 0.1875rem;
  color: #fff;
}
.advantage .brief[data-v-fa0cdada] {
  font-size: 0.10416667rem;
  color: hsla(0,0%,100%,.6);
  margin-top: 0.17708333rem;
}
.advantage .tabs[data-v-fa0cdada] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.41666667rem;
  border-bottom: 0.01041667rem solid hsla(0,0%,100%,.6);
  position: relative;
  margin-top: 0.41666667rem;
}
.advantage .tabs .item[data-v-fa0cdada] {
  text-align: center;
  font-size: 0.10416667rem;
  color: #fff;
  padding-bottom: 0.20833333rem;
  position: relative;
  cursor: pointer;
}
.advantage .tabs .item .icon[data-v-fa0cdada] {
  width: 0.10416667rem;
}
.advantage .tabs .item .txt[data-v-fa0cdada] {
  margin-top: 0.04166667rem;
}
.advantage .tabs .item-strip[data-v-fa0cdada] {
  position: absolute;
  top: 100%;
  left: 0;
  height: 0.02083333rem;
  width: 33.3%;
  background: #4e99fd;
  margin-top: -0.00520833rem;
  transition: all .5s;
}
.advantage .content[data-v-fa0cdada] {
  display: flex;
  align-items: flex-start;
  gap: 0.20833333rem;
  margin-top: 0.20833333rem;
}
.advantage .content .img[data-v-fa0cdada] {
  width: 2.72916667rem;
}
.advantage .content .right[data-v-fa0cdada] {
  text-align: left;
}
.advantage .content .right .right-title[data-v-fa0cdada] {
  font-size: 0.14583333rem;
  color: #fff;
  margin-bottom: 0.05208333rem;
}
.advantage .content .right .right-brief[data-v-fa0cdada] {
  font-size: 0.09375rem;
  color: hsla(0,0%,100%,.8);
  line-height: 2;
}
.application[data-v-fa0cdada] {
  padding: 0.41666667rem 0;
  background: #f7fbff;
}