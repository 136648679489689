.relative[data-v-b275d18e] {
  position: relative;
}
.w-full[data-v-b275d18e] {
  width: 100%;
}
.relative-center[data-v-b275d18e] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-b275d18e] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-b275d18e] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-b275d18e] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-b275d18e] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-b275d18e] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-b275d18e] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-b275d18e] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-b275d18e] {
  justify-content: space-between;
}
.flex[data-v-b275d18e] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-b275d18e] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-b275d18e],
.card-list-no-padding .ci-description[data-v-b275d18e] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-b275d18e] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.car-monitor .use-bg[data-v-b275d18e] {
  background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
  background-size: cover;
}
.car-monitor .b3-img[data-v-b275d18e] {
  object-fit: scale-down;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 6.71354167rem;
}