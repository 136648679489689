.relative[data-v-24cf6514] {
  position: relative;
}
.w-full[data-v-24cf6514] {
  width: 100%;
}
.relative-center[data-v-24cf6514] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-24cf6514] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-24cf6514] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-24cf6514] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-24cf6514] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-24cf6514] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-24cf6514] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-24cf6514] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-24cf6514] {
  justify-content: space-between;
}
.flex[data-v-24cf6514] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-24cf6514] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-24cf6514],
.card-list-no-padding .ci-description[data-v-24cf6514] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-24cf6514] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.box[data-v-24cf6514] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.structure[data-v-24cf6514] {
  padding: 0.41666667rem 0 0.20833333rem;
  background: url(@/asset/smart-port/ic_port_48.png) center/cover no-repeat;
}
.structure .img[data-v-24cf6514] {
  display: block;
  margin-top: 0.20833333rem;
}
.core[data-v-24cf6514] {
  padding: 0.41666667rem 0;
}
.value[data-v-24cf6514] {
  padding: 0.41666667rem 0 0.20833333rem;
  background: url(@/asset/smart-port/ic_port_52.png) center/cover no-repeat;
}
.value .value-title[data-v-24cf6514] {
  font-family: YouSheBiaoTiHei;
  font-size: 0.1875rem;
  color: #fff;
  margin-bottom: 0.06770833rem;
  text-align: center;
}
.value .value-en[data-v-24cf6514] {
  font-size: 0.125rem;
  color: hsla(0,0%,100%,.4);
  margin-bottom: 0.20833333rem;
  text-align: center;
}
.value .value-brief[data-v-24cf6514] {
  font-size: 0.125rem;
  color: hsla(0,0%,100%,.6);
  margin-bottom: 0.41666667rem;
  text-align: center;
}
.value .list[data-v-24cf6514] {
  display: flex;
  gap: 0.55208333rem;
  justify-content: center;
}
.value .list .item[data-v-24cf6514] {
  width: 1.85416667rem;
  background: #fff;
}
.value .list .item .img[data-v-24cf6514] {
  width: 100%;
}
.value .list .item .txt[data-v-24cf6514] {
  padding: 0.20833333rem;
  text-align: center;
}
.value .list .item .txt .title[data-v-24cf6514] {
  font-size: 0.125rem;
  color: #333;
}
.value .list .item .txt .brief[data-v-24cf6514] {
  font-size: 0.08333333rem;
  color: #666;
  line-height: 2;
  margin-top: 0.0625rem;
}
.platform[data-v-24cf6514] {
  padding: 0.41666667rem 0;
}
.platform .img[data-v-24cf6514] {
  width: 100%;
  margin-top: 0.20833333rem;
  display: block;
}
.case[data-v-24cf6514] {
  padding: 0.41666667rem 0 0.20833333rem;
  background: url(@/asset/smart-port/ic_port_41.png) center/cover no-repeat;
}
.footer-resolution[data-v-24cf6514] {
  height: 1.58333333rem;
  background-size: cover;
}
.footer-resolution .layout[data-v-24cf6514] {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.10416667rem;
}
.footer-resolution .f-title[data-v-24cf6514] {
  font-family: YouSheBiaoTiHei,YouSheBiaoTiHei;
  font-weight: 400;
  font-size: 0.1875rem;
  color: #333;
  margin-top: 0.3125rem;
}
.footer-resolution .f-en[data-v-24cf6514] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #999;
  margin-top: 0.05208333rem;
}
.footer-resolution .f-sub[data-v-24cf6514] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #999;
  margin-top: 0.20833333rem;
}
.footer-resolution .ask-btn[data-v-24cf6514] {
  width: 1.04166667rem;
  height: 0.3125rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  box-shadow: 0.015625rem 0.015625rem 0.04166667rem 0.00520833rem #e8e8e8;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.52083333rem;
}