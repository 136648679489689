.relative[data-v-f4ed93fe] {
  position: relative;
}
.w-full[data-v-f4ed93fe] {
  width: 100%;
}
.relative-center[data-v-f4ed93fe] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-f4ed93fe] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-f4ed93fe] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-f4ed93fe] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-f4ed93fe] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-f4ed93fe] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-f4ed93fe] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-f4ed93fe] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-f4ed93fe] {
  justify-content: space-between;
}
.flex[data-v-f4ed93fe] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-f4ed93fe] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-f4ed93fe],
.card-list-no-padding .ci-description[data-v-f4ed93fe] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-f4ed93fe] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.introduce-carousel[data-v-f4ed93fe] {
  box-sizing: border-box;
  position: relative;
}
.introduce-carousel .i-bg[data-v-f4ed93fe] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -1;
}
.introduce-carousel .title-content[data-v-f4ed93fe] {
  text-align: center;
}
.introduce-carousel .title[data-v-f4ed93fe] {
  font-family: YouSheBiaoTiHei,YouSheBiaoTiHei;
  font-weight: 400;
  font-size: 0.1875rem;
  color: #fff;
}
.introduce-carousel .sub-title-en[data-v-f4ed93fe] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bold;
  font-size: 0.1875rem;
  color: hsla(0,0%,100%,.4);
  margin-top: 0.06770833rem;
}
.introduce-carousel .sub-title[data-v-f4ed93fe] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.10416667rem;
  color: hsla(0,0%,100%,.6);
  margin-top: 0.20833333rem;
}
.introduce-carousel .block-content[data-v-f4ed93fe] {
  display: grid;
  grid-template-columns: 1fr 3.29166667rem;
  gap: 0.52083333rem;
  align-items: flex-start;
}
.introduce-carousel .b-left[data-v-f4ed93fe] {
  display: flex;
  flex-direction: column;
}
.introduce-carousel .b-nav[data-v-f4ed93fe] {
  display: grid;
}
.introduce-carousel .bn-item[data-v-f4ed93fe] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.introduce-carousel .bn-item span[data-v-f4ed93fe] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.10416667rem;
  color: #fff;
  margin-top: 0.04166667rem;
}
.introduce-carousel .bn-item .hover[data-v-f4ed93fe] {
  display: none;
}
.introduce-carousel .bn-item:hover span[data-v-f4ed93fe],
.introduce-carousel .bn-item.checked span[data-v-f4ed93fe] {
  font-weight: bold;
  font-size: 0.10416667rem;
  color: #fff;
}
.introduce-carousel .bn-item:hover .hover[data-v-f4ed93fe],
.introduce-carousel .bn-item.checked .hover[data-v-f4ed93fe] {
  display: block;
}
.introduce-carousel .bn-item:hover .normal[data-v-f4ed93fe],
.introduce-carousel .bn-item.checked .normal[data-v-f4ed93fe] {
  display: none;
}
.introduce-carousel .b-border[data-v-f4ed93fe] {
  position: relative;
  width: 100%;
  height: 0.01041667rem;
  background: hsla(0,0%,100%,.6);
  margin-top: 0.20833333rem;
}
.introduce-carousel .b-border .b-border-inner[data-v-f4ed93fe] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 0.02083333rem;
  background: #4e99fd;
  transition: left .2s;
}
.introduce-carousel .b-sub[data-v-f4ed93fe] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.1875rem;
  color: #fff;
  margin-top: 0.20833333rem;
}
.introduce-carousel .b-description[data-v-f4ed93fe] {
  margin-top: 0.125rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.10416667rem;
  color: #fff;
  line-height: 0.20833333rem;
}
.introduce-carousel .b-right div[data-v-f4ed93fe] {
  position: relative;
}
.introduce-carousel .b-right div img[data-v-f4ed93fe] {
  position: absolute;
}
.opacity-enter-active[data-v-f4ed93fe] {
  animation: fadeIn-f4ed93fe .3s;
}
.opacity-leave-active[data-v-f4ed93fe] {
  animation: fadeIn-f4ed93fe .3s reverse;
}
@keyframes fadeIn-f4ed93fe {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}