.relative[data-v-798a5d83] {
  position: relative;
}
.w-full[data-v-798a5d83] {
  width: 100%;
}
.relative-center[data-v-798a5d83] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-798a5d83] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-798a5d83] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-798a5d83] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-798a5d83] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-798a5d83] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-798a5d83] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-798a5d83] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-798a5d83] {
  justify-content: space-between;
}
.flex[data-v-798a5d83] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-798a5d83] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-798a5d83],
.card-list-no-padding .ci-description[data-v-798a5d83] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-798a5d83] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.about[data-v-798a5d83] {
  padding: 0.41666667rem 0;
}
.about .tabs[data-v-798a5d83] {
  display: flex;
  align-items: center;
  gap: 0.10416667rem;
  margin-top: 0.20833333rem;
}
.about .tabs .item[data-v-798a5d83] {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0.20833333rem;
  background: #fff;
  position: relative;
  cursor: pointer;
  gap: 0.08333333rem;
}
.about .tabs .item .img[data-v-798a5d83] {
  width: 0.29166667rem;
  object-fit: contain;
  flex-shrink: 0;
}
.about .tabs .item .right[data-v-798a5d83] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.about .tabs .item .right .title[data-v-798a5d83] {
  font-size: 0.125rem;
  color: #333;
}
.about .tabs .item .right .brief[data-v-798a5d83] {
  font-size: 0.08333333rem;
  color: #666;
}
.about .tabs .active[data-v-798a5d83] {
  box-shadow: 0 0.015625rem 0.03125rem 0.00520833rem rgba(0,0,0,.16);
}
.about .tabs .active[data-v-798a5d83]::before {
  content: "";
  position: absolute;
  width: 0.09375rem;
  height: 0.09375rem;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  box-shadow: 0 0.015625rem 0.03125rem 0.00520833rem rgba(0,0,0,.16);
  background: #fff;
}
.about .tabs .active[data-v-798a5d83]::after {
  content: "";
  position: absolute;
  width: 0.14583333rem;
  height: 0.14583333rem;
  bottom: 0;
  left: 50%;
  background: #fff;
  transform: translateX(-50%);
}
.display[data-v-798a5d83] {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0.26041667rem;
}
.display .info[data-v-798a5d83] {
  width: 2.60416667rem;
  height: 1.14583333rem;
  margin-top: 0.3125rem;
  padding: 0.3125rem;
  padding-right: 0.15625rem;
  border-left: 0.05208333rem solid #ddd;
  border-bottom: 0.05208333rem solid #ddd;
}
.display .info .title[data-v-798a5d83] {
  font-size: 0.125rem;
  color: #4e99fd;
  margin-bottom: 0.15625rem;
}
.display .info .info-item[data-v-798a5d83] {
  position: relative;
  font-size: 0.09375rem;
  color: #666;
  line-height: 2;
  padding-left: 0.10416667rem;
}
.display .info .info-item[data-v-798a5d83]::after {
  content: "";
  width: 0.0625rem;
  height: 0.0625rem;
  border-radius: 50%;
  background: #4e99fd;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.display .info-img[data-v-798a5d83] {
  width: 3.23958333rem;
}
.drive[data-v-798a5d83] {
  line-height: 1;
  padding: 0.41666667rem 0;
  transition: .5s all;
}
.drive .en-title[data-v-798a5d83] {
  font-size: 0.1875rem;
  color: hsla(0,0%,100%,.4);
  margin-bottom: 0.05208333rem;
  text-align: center;
}
.drive .ch-title[data-v-798a5d83] {
  font-size: 0.1875rem;
  color: #fff;
  margin-bottom: 0.17708333rem;
  text-align: center;
  font-family: YouSheBiaoTiHei;
}
.drive .ch-brief[data-v-798a5d83] {
  font-size: 0.10416667rem;
  color: hsla(0,0%,100%,.6);
  text-align: center;
  margin-bottom: 0.41666667rem;
}
.drive .content[data-v-798a5d83] {
  display: flex;
  height: 2.1875rem;
}
.drive .content .left[data-v-798a5d83] {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-right: 0.01041667rem solid hsla(0,0%,100%,.6);
  flex-shrink: 0;
}
.drive .content .left .item[data-v-798a5d83] {
  display: flex;
  align-items: center;
  gap: 0.04166667rem;
  padding-right: 0.20833333rem;
  cursor: pointer;
}
.drive .content .left .item .item-icon[data-v-798a5d83] {
  width: 0.10416667rem;
}
.drive .content .left .item .item-txt[data-v-798a5d83] {
  font-size: 0.10416667rem;
  font-weight: bold;
  color: #fff;
}
.drive .content .left .item-strip[data-v-798a5d83] {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  width: 0.02083333rem;
  height: 20%;
  transition: all .5s;
  margin-right: -0.00520833rem;
  background: #4e99fd;
}
.drive .content .right[data-v-798a5d83] {
  padding-left: 0.41666667rem;
}
.drive .content .right .item .title[data-v-798a5d83] {
  font-size: 0.1875rem;
  color: #fff;
}
.drive .content .right .item .brief[data-v-798a5d83] {
  font-size: 0.10416667rem;
  line-height: 2;
  margin: 0.20833333rem 0;
  color: hsla(0,0%,100%,.6);
}
.more-button[data-v-798a5d83] {
  width: 1.13020833rem;
  height: 0.31770833rem;
  background: #4e99fd;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-size: 0.125rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.0625rem;
  cursor: pointer;
}
.more-button .more-icon[data-v-798a5d83] {
  width: 0.15625rem;
}
.advantage[data-v-798a5d83] {
  padding: 0.41666667rem 0;
}
.advantage .content[data-v-798a5d83] {
  display: flex;
  justify-content: center;
  gap: 0.125rem;
  margin-top: 0.3125rem;
}
.advantage .content .left[data-v-798a5d83] {
  color: #fff;
  background: url(../asset/index/ic_index_21.png) no-repeat center/cover;
  padding: 0.36458333rem 0.20833333rem;
  width: 2.22395833rem;
  height: 3.47395833rem;
  box-sizing: border-box;
  flex-shrink: 0;
}
.advantage .content .left .icon[data-v-798a5d83] {
  width: 0.3125rem;
  margin-bottom: 0.20833333rem;
}
.advantage .content .left .title[data-v-798a5d83] {
  font-size: 0.14583333rem;
  margin-bottom: 0.04166667rem;
}
.advantage .content .left .en-title[data-v-798a5d83] {
  font-size: 0.0625rem;
  margin-bottom: 0.20833333rem;
  color: hsla(0,0%,100%,.8);
}
.advantage .content .left .brief[data-v-798a5d83] {
  font-size: 0.08333333rem;
  line-height: 2;
  color: hsla(0,0%,100%,.8);
}
.advantage .content .right[data-v-798a5d83] {
  display: flex;
  flex-wrap: wrap;
  width: 3.59375rem;
  gap: 0.125rem;
}
.advantage .content .right .item[data-v-798a5d83] {
  padding: 0.20833333rem;
  width: 1.70833333rem;
  height: 1.66666667rem;
  box-sizing: border-box;
  border: 0.00520833rem solid #4e99fd;
  border-radius: 0.02083333rem;
}
.advantage .content .right .item .icon[data-v-798a5d83] {
  height: 0.3125rem;
  margin-bottom: 0.20833333rem;
}
.advantage .content .right .item .title[data-v-798a5d83] {
  font-size: 0.14583333rem;
  margin-bottom: 0.04166667rem;
}
.advantage .content .right .item .en-title[data-v-798a5d83] {
  font-size: 0.0625rem;
  margin-bottom: 0.20833333rem;
  color: hsla(0,0%,60%,.8);
}
.advantage .content .right .item .brief[data-v-798a5d83] {
  font-size: 0.08333333rem;
  line-height: 2;
  color: #666;
}
.case[data-v-798a5d83] {
  padding: 0.41666667rem 0;
  background: url(../asset/index/ic_index_68.png) no-repeat center/cover;
}
.case .list[data-v-798a5d83] {
  display: flex;
  justify-content: space-between;
  margin-top: 0.41666667rem;
  margin-bottom: 0.125rem;
}
.case .list .item[data-v-798a5d83] {
  width: 1.5625rem;
  height: 2.5rem;
  position: relative;
}
.case .list .item .hover[data-v-798a5d83] {
  padding: 0.125rem 0.20833333rem;
  background: hsla(0,0%,100%,.8);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: all .5s;
  box-sizing: border-box;
  height: 0.54166667rem;
  box-sizing: border-box;
  overflow: hidden;
}
.case .list .item .hover .title[data-v-798a5d83] {
  color: #333;
  font-size: 0.125rem;
  margin-bottom: 0.08333333rem;
}
.case .list .item .hover .sub[data-v-798a5d83] {
  color: #666;
  font-size: 0.08333333rem;
}
.case .list .item .hover .brief[data-v-798a5d83] {
  color: #fff;
  font-size: 0.08333333rem;
  line-height: 2;
  margin-top: 0.20833333rem;
  transition: .5s all;
}
.case .list .item:hover .hover[data-v-798a5d83] {
  height: 100%;
  background: rgba(78,153,253,.6);
}
.case .list .item:hover .title[data-v-798a5d83],
.case .list .item:hover .sub[data-v-798a5d83] {
  color: #fff;
}
.confirm[data-v-798a5d83] {
  padding-top: 0.5rem;
}
.confirm .content[data-v-798a5d83] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 10rem;
  margin: 0 auto;
  margin-top: 0.35416667rem;
}
.confirm .content .swiper-list[data-v-798a5d83] {
  position: relative;
  height: 2.63020833rem;
  flex-grow: 1;
  overflow: hidden;
}
.confirm .content .swiper-list .swiper-item[data-v-798a5d83] {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: .5s all;
  text-align: center;
  display: flex;
  align-items: flex-start;
  gap: 0.41666667rem;
}
.confirm .content .swiper-list .swiper-item .swiper-img[data-v-798a5d83] {
  width: 5rem;
  height: 2.63020833rem;
}
.confirm .content .swiper-list .swiper-item .swiper-right[data-v-798a5d83] {
  text-align: left;
  padding-right: 0.98958333rem;
}
.confirm .content .swiper-list .swiper-item .swiper-right .swiper-num[data-v-798a5d83] {
  margin-bottom: 0.20833333rem;
}
.confirm .content .swiper-list .swiper-item .swiper-right .swiper-num .sp[data-v-798a5d83] {
  font-size: 0.20833333rem;
  color: #4e99fd;
  font-weight: bold;
}
.confirm .content .swiper-list .swiper-item .swiper-right .swiper-num .count[data-v-798a5d83] {
  font-size: 0.09375rem;
  color: #ddd;
}
.confirm .content .swiper-list .swiper-item .swiper-right .swiper-en[data-v-798a5d83] {
  color: #ddd;
  font-size: 0.125rem;
  position: relative;
  margin-bottom: 0.08333333rem;
  display: inline-block;
}
.confirm .content .swiper-list .swiper-item .swiper-right .swiper-en[data-v-798a5d83]::after {
  content: "";
  position: absolute;
  width: 0.41666667rem;
  height: 0.02083333rem;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0.125rem;
  background: #4e99fd;
}
.confirm .content .swiper-list .swiper-item .swiper-right .swiper-title[data-v-798a5d83] {
  font-size: 0.125rem;
  color: #4e99fd;
  margin-bottom: 0.36458333rem;
  font-weight: bold;
}
.confirm .content .swiper-list .swiper-item .swiper-right .swiper-brief[data-v-798a5d83] {
  font-size: 0.09375rem;
  color: #666;
  line-height: 2;
  margin-bottom: 0.15625rem;
  width: 2.60416667rem;
}
.confirm .content .swiper-list .swiper-item .swiper-right .swiper-more[data-v-798a5d83] {
  font-size: 0.09375rem;
  color: #4e99fd;
  gap: 0.04166667rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.confirm .content .swiper-list .swiper-item .swiper-right .swiper-more .swiper-icon[data-v-798a5d83] {
  width: 0.15625rem;
}
.confirm .content .next[data-v-798a5d83] {
  flex-shrink: 0;
  position: absolute;
  right: 0.98958333rem;
  top: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.confirm .content .indicator[data-v-798a5d83] {
  position: absolute;
  bottom: 0;
  left: 5.41666667rem;
  display: flex;
  align-items: center;
  gap: 0.125rem;
}
.confirm .content .indicator .indicator-item[data-v-798a5d83] {
  width: 0.0625rem;
  height: 0.0625rem;
  border-radius: 50%;
  background: #4e99fd;
  opacity: .2;
  cursor: pointer;
}
.confirm .content .indicator .indicator-item[data-v-798a5d83]:hover {
  opacity: .8;
}
.confirm .content .indicator .indicator-item-active[data-v-798a5d83] {
  opacity: .8;
}
.strip[data-v-798a5d83] {
  margin-top: 0.625rem;
  position: relative;
}
.strip .bg[data-v-798a5d83] {
  width: 100%;
}
.strip .content[data-v-798a5d83] {
  position: absolute;
  box-sizing: border-box;
  top: 27%;
  right: calc((100% - 10rem)/2 + 1.66666667rem);
}
.strip .content .title[data-v-798a5d83] {
  color: #333;
  font-size: 0.1875rem;
  margin-bottom: 0.046875rem;
  font-family: YouSheBiaoTiHei;
  text-align: center;
}
.strip .content .en[data-v-798a5d83] {
  color: rgba(51,51,51,.4);
  font-size: 0.125rem;
  margin-bottom: 0.41666667rem;
  text-align: center;
}
.strip .content .digital[data-v-798a5d83] {
  display: flex;
  justify-content: center;
  gap: 0.72916667rem;
}
.strip .content .digital .item .num[data-v-798a5d83] {
  font-size: 0.25rem;
  color: #4e99fd;
  font-weight: bold;
}
.strip .content .digital .item .txt[data-v-798a5d83] {
  font-size: 0.09375rem;
  color: #666;
  margin-top: 0.08333333rem;
}
.apply[data-v-798a5d83] {
  margin: 0.52083333rem 0;
}
.apply .tabs[data-v-798a5d83] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.36458333rem;
  margin-bottom: 0.20833333rem;
  margin-top: 0.41666667rem;
}
.apply .tabs .item[data-v-798a5d83] {
  width: 1.04166667rem;
  height: 0.51041667rem;
  display: flex;
  align-items: center;
  gap: 0.08333333rem;
  justify-content: center;
  background: #fff;
  box-shadow: 0.015625rem 0.015625rem 0.04166667rem 0.00520833rem #e8e8e8;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-size: 0.125rem;
  color: #333;
  cursor: pointer;
}
.apply .tabs .item .icon[data-v-798a5d83] {
  width: 0.16666667rem;
}
.apply .tabs .item .hover[data-v-798a5d83] {
  display: none;
}
.apply .tabs .item[data-v-798a5d83]:hover,
.apply .tabs .item.active[data-v-798a5d83] {
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  color: #fff;
}
.apply .tabs .item:hover .icon[data-v-798a5d83],
.apply .tabs .item.active .icon[data-v-798a5d83] {
  display: none;
}
.apply .tabs .item:hover .hover[data-v-798a5d83],
.apply .tabs .item.active .hover[data-v-798a5d83] {
  display: block;
}
.apply .form[data-v-798a5d83] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.36458333rem;
}
.apply .form .name[data-v-798a5d83],
.apply .form .phone[data-v-798a5d83] {
  font-size: 0.125rem;
  border: 0.00520833rem solid #ddd;
  outline: none;
  height: 0.3125rem;
  padding: 0 0.14583333rem;
  width: 2.44791667rem;
  box-sizing: border-box;
}
.apply .form .button[data-v-798a5d83] {
  flex-shrink: 0;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  box-shadow: 0.015625rem 0.015625rem 0.04166667rem 0.00520833rem #e8e8e8;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  width: 1.04166667rem;
  height: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.125rem;
  cursor: pointer;
}