.custom-select {
  position: relative;
  width: 1.16666667rem;
}
.selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.05208333rem 0.0625rem;
  border: 0.00520833rem solid rgba(221, 221, 221, 1);
  border-radius: 0.02083333rem;
  cursor: pointer;
  transition: all .2s;
  height: 0.3125rem;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 0.07291667rem;
  box-sizing: border-box;
}
.isPlaceHolder {
  color: rgba(153, 153, 153, 1);
}
.selected-option:hover {
  background-color: #f5f5f5;
}
.arrow {
  border: solid #999999;
  border-width: 0 0.01041667rem 0.01041667rem 0;
  display: inline-block;
  padding: 0.015625rem;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.options {
  position: absolute;
  top: calc(100% + 0.02604167rem);
  left: 0;
  width: 100%;
  max-height: 1.04166667rem;
  overflow-y: auto;
  background-color: #ffffff;
  border: 0.00520833rem solid rgba(221, 221, 221, 1);
  border-radius: 0.02083333rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.options li {
  padding: 0.05208333rem;
  cursor: pointer;
  transition: all .2s;
}
.options li:hover {
  background-color: #f2f2f2;
}