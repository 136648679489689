.relative[data-v-5b4bea06] {
  position: relative;
}
.w-full[data-v-5b4bea06] {
  width: 100%;
}
.relative-center[data-v-5b4bea06] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-5b4bea06] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-5b4bea06] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-5b4bea06] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-5b4bea06] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-5b4bea06] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-5b4bea06] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-5b4bea06] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-5b4bea06] {
  justify-content: space-between;
}
.flex[data-v-5b4bea06] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-5b4bea06] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-5b4bea06],
.card-list-no-padding .ci-description[data-v-5b4bea06] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-5b4bea06] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.pipe-gallery .use-bg[data-v-5b4bea06] {
  background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
  background-size: cover;
}
.pipe-gallery .b2-img[data-v-5b4bea06] {
  object-fit: scale-down;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 3.13020833rem;
}
.pipe-gallery .sp[data-v-5b4bea06] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.09375rem;
  color: #666;
  line-height: 0.1875rem;
}
.pipe-gallery .carousel[data-v-5b4bea06] .b-nav {
  grid-template-columns: repeat(3, auto) !important;
  justify-content: space-between;
}