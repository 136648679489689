.relative[data-v-7c88af5e] {
  position: relative;
}
.w-full[data-v-7c88af5e] {
  width: 100%;
}
.relative-center[data-v-7c88af5e] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-7c88af5e] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-7c88af5e] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-7c88af5e] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-7c88af5e] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-7c88af5e] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-7c88af5e] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-7c88af5e] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-7c88af5e] {
  justify-content: space-between;
}
.flex[data-v-7c88af5e] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-7c88af5e] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-7c88af5e],
.card-list-no-padding .ci-description[data-v-7c88af5e] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-7c88af5e] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.digital-quarry .use-bg[data-v-7c88af5e] {
  background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
  background-size: cover;
}
.digital-quarry .b4-img[data-v-7c88af5e] {
  object-fit: scale-down;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 4.32291667rem;
}
.digital-quarry .b1-list[data-v-7c88af5e] {
  display: grid;
  grid-template-columns: repeat(3, 1.85416667rem);
  grid-template-rows: 2.734375rem 2.734375rem;
  row-gap: 0.41666667rem;
  justify-content: space-between;
}
.digital-quarry .b1-list img[data-v-7c88af5e] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.digital-quarry .b1-list .b-item[data-v-7c88af5e] {
  padding: 0.20833333rem;
  box-sizing: border-box;
  position: relative;
}
.digital-quarry .b1-list .b-item[data-v-7c88af5e]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: background-color .2s;
  background: rgba(0,0,0,0);
}
.digital-quarry .b1-list .b-item:hover .b-description[data-v-7c88af5e] {
  color: #fff;
}
.digital-quarry .b1-list .b-item[data-v-7c88af5e]:hover:before {
  background: rgba(78,153,253,.75);
}
.digital-quarry .b1-list .b-title[data-v-7c88af5e] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #fff;
}
.digital-quarry .b1-list .b-hr[data-v-7c88af5e] {
  width: 0.38020833rem;
  height: 0.04166667rem;
  background: #4e99fd;
  border-radius: 0.03645833rem 0.03645833rem 0.03645833rem 0.03645833rem;
}
.digital-quarry .b1-list .b-sub[data-v-7c88af5e] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #fff;
}
.digital-quarry .b1-list .b-description[data-v-7c88af5e] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  line-height: 0.16666667rem;
  color: rgba(0,0,0,0);
  transition: color .2s;
}