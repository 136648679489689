.relative[data-v-3a1a3cf6] {
  position: relative;
}
.w-full[data-v-3a1a3cf6] {
  width: 100%;
}
.relative-center[data-v-3a1a3cf6] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-3a1a3cf6] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-3a1a3cf6] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-3a1a3cf6] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-3a1a3cf6] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-3a1a3cf6] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-3a1a3cf6] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-3a1a3cf6] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-3a1a3cf6] {
  justify-content: space-between;
}
.flex[data-v-3a1a3cf6] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-3a1a3cf6] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-3a1a3cf6],
.card-list-no-padding .ci-description[data-v-3a1a3cf6] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-3a1a3cf6] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.empty[data-v-3a1a3cf6] {
  background: #edf0f5;
  left: 0;
  right: 0;
  top: 0.52083333rem;
  bottom: 0;
  position: fixed;
  text-align: center;
}
.empty .img[data-v-3a1a3cf6] {
  margin-top: 0.52083333rem;
  width: 5.72916667rem;
}
.empty .txt[data-v-3a1a3cf6] {
  font-size: 0.125rem;
  color: #666;
  position: relative;
  top: -0.33333333rem;
}
.empty .button[data-v-3a1a3cf6] {
  width: 1.04166667rem;
  height: 0.3125rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  box-shadow: 0.015625rem 0.015625rem 0.04166667rem 0.00520833rem #e8e8e8;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  line-height: 0.3125rem;
  text-align: center;
  font-size: 0.125rem;
  color: #fff;
  margin: 0 auto;
  cursor: pointer;
}