/* layer: preflights */

*,
::before,
::after {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
  --un-shadow-inset: ;
  --un-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-inset: ;
  --un-ring-offset-width: 0;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0;
  --un-ring-color: rgb(147 197 253 / 0.5);
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia: ;
}

::backdrop {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
  --un-shadow-inset: ;
  --un-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-inset: ;
  --un-ring-offset-width: 0;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0;
  --un-ring-color: rgb(147 197 253 / 0.5);
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia: ;
}

/* layer: shortcuts */

.absolute-bl,
.absolute-lb {
  position: absolute;
  left: 0;
  bottom: 0;
}

.absolute-br,
.absolute-rb {
  position: absolute;
  right: 0;
  bottom: 0;
}

.absolute-center {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.absolute-lt,
.absolute-tl {
  position: absolute;
  left: 0;
  top: 0;
}

.absolute-rt,
.absolute-tr {
  position: absolute;
  right: 0;
  top: 0;
}

.fixed-bl,
.fixed-lb {
  position: fixed;
  left: 0;
  bottom: 0;
}

.fixed-br,
.fixed-rb {
  position: fixed;
  right: 0;
  bottom: 0;
}

.fixed-center {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-lt,
.fixed-tl {
  position: fixed;
  left: 0;
  top: 0;
}

.fixed-rt,
.fixed-tr {
  position: fixed;
  right: 0;
  top: 0;
}

.wh-full {
  width: 100%;
  height: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-col-stretch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.flex-x-center {
  display: flex;
  justify-content: center;
}

.flex-y-center {
  display: flex;
  align-items: center;
}

.i-flex-col {
  display: flex;
  display: inline-flex;
  flex-direction: column;
}

.i-flex-col-stretch {
  display: flex;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}

.i-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.i-flex-x-center {
  display: inline-flex;
  justify-content: center;
}

.i-flex-y-center {
  display: inline-flex;
  align-items: center;
}

.flex-1-hidden {
  flex: 1 1 0%;
  overflow: hidden;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nowrap-hidden {
  overflow: hidden;
  white-space: nowrap;
}

.transition-base {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

/* layer: default */

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.static {
  position: static;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.grid {
  display: grid;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-12px {
  margin-top: 0.0625rem;
}

.mt-80px {
  margin-top: 0.41666667rem;
}

.mt-8px {
  margin-top: 0.04166667rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.h-32px {
  height: 0.16666667rem;
}

.h-96px\! {
  height: 0.5rem !important;
}

.h-full {
  height: 100%;
}

.h-full\! {
  height: 100% !important;
}

.w-200px {
  width: 1.04166667rem;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.transform {
  -webkit-transform: translateX(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotateZ(var(--un-rotate-z)) skewX(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z));
  transform: translateX(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotateZ(var(--un-rotate-z)) skewX(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.b,
.border {
  border-width: 0.00520833rem;
}

.bg-black {
  --un-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--un-bg-opacity));
}

.px {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px2rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pb-40px {
  padding-bottom: 0.20833333rem;
}

.pb-80px {
  padding-bottom: 0.41666667rem;
}

.pl-6px\! {
  padding-left: 0.03125rem !important;
}

.pt-100px {
  padding-top: 0.52083333rem;
}

.pt-10px\! {
  padding-top: 0.05208333rem !important;
}

.pt-80px {
  padding-top: 0.41666667rem;
}

.text-16px {
  font-size: 0.08333333rem;
}

.text-32px {
  font-size: 0.16666667rem;
}

.text-stroke {
  -webkit-text-stroke-width: 1.5rem;
}

.outline {
  outline-style: solid;
}

.filter {
  -webkit-filter: var(--un-blur) var(--un-brightness) var(--un-contrast) var(--un-drop-shadow) var(--un-grayscale) var(--un-hue-rotate) var(--un-invert) var(--un-saturate) var(--un-sepia);
  filter: var(--un-blur) var(--un-brightness) var(--un-contrast) var(--un-drop-shadow) var(--un-grayscale) var(--un-hue-rotate) var(--un-invert) var(--un-saturate) var(--un-sepia);
}

.transition {
  transition-property: color,background-color,border-color,outline-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: color,background-color,border-color,outline-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@font-face {
  font-family: AlibabaPuHuiTi-3-55-Regular;
  src: url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.eot) format('embedded-opentype'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.otf) format('opentype'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.ttf) format('TrueType'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.woff) format('woff'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.woff2) format('woff2');
}

@font-face {
  font-family: Source Han Sans CN-Medium;
  src: url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.eot) format('embedded-opentype'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.otf) format('opentype'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.ttf) format('TrueType'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.woff) format('woff'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.woff2) format('woff2');
}

@font-face {
  font-family: YouSheBiaoTiHei;
  src: url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/YouSheBiaoTiHei.woff2) format('TrueType');
}

* {
  font-family: AlibabaPuHuiTi-3-55-Regular, serif;
}

.main {
  max-width: 7.5rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 1500px) {
  .main {
    padding: 0 0.10416667rem;
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 0.125rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  html {
    font-size: 0.125rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 0.125rem;
  }
}