.relative[data-v-b8fab462] {
  position: relative;
}
.w-full[data-v-b8fab462] {
  width: 100%;
}
.relative-center[data-v-b8fab462] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-b8fab462] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-b8fab462] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-b8fab462] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-b8fab462] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-b8fab462] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-b8fab462] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-b8fab462] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-b8fab462] {
  justify-content: space-between;
}
.flex[data-v-b8fab462] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-b8fab462] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-b8fab462],
.card-list-no-padding .ci-description[data-v-b8fab462] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-b8fab462] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.structure[data-v-b8fab462] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.structure .card-box[data-v-b8fab462] {
  margin-bottom: 0.41666667rem;
}
.structure .img[data-v-b8fab462] {
  display: block;
  margin-top: 0.20833333rem;
}
.system[data-v-b8fab462] {
  padding: 0.41666667rem 0 0.20833333rem;
  background: url(@/asset/smart-traffic/ic_traffic_21.png) no-repeat center/cover;
}
.advantage[data-v-b8fab462] {
  padding: 0.41666667rem 0;
}
.service[data-v-b8fab462] {
  padding: 0.41666667rem 0;
  background: url(@/asset/smart-traffic/ic_traffic_21.png) no-repeat center/cover;
}