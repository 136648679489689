.relative[data-v-3a11801c] {
  position: relative;
}
.w-full[data-v-3a11801c] {
  width: 100%;
}
.relative-center[data-v-3a11801c] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-3a11801c] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-3a11801c] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-3a11801c] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-3a11801c] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-3a11801c] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-3a11801c] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-3a11801c] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-3a11801c] {
  justify-content: space-between;
}
.flex[data-v-3a11801c] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-3a11801c] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-3a11801c],
.card-list-no-padding .ci-description[data-v-3a11801c] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-3a11801c] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.bannerTxt[data-v-3a11801c] {
  font-family: YouSheBiaoTiHei;
  font-size: 0.3125rem;
  color: #fff;
  margin-bottom: 0.125rem;
  text-align: center;
}
.service[data-v-3a11801c] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.service .list[data-v-3a11801c] {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3125rem 0.125rem;
  margin-top: 0.41666667rem;
}
.service .list .item[data-v-3a11801c] {
  background: #fff;
  box-shadow: 0.015625rem 0.015625rem 0.10416667rem 0.00520833rem rgba(221,221,221,.4);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  width: 1.5625rem;
  height: 1.82291667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.service .list .item .img[data-v-3a11801c] {
  width: 80%;
}
.service .list .item .hover[data-v-3a11801c] {
  position: absolute;
  left: 0.0625rem;
  right: 0.0625rem;
  bottom: 0.0625rem;
  transition: all .5s;
  border-radius: 0.04166667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.07291667rem;
  color: #666;
  height: 1em;
}
.service .list .item:hover .hover[data-v-3a11801c] {
  background: rgba(78,153,253,.6);
  height: 0.72916667rem;
  color: #fff;
}
.theory[data-v-3a11801c] {
  padding: 0.41666667rem 0 0.20833333rem;
  background: url(@/asset/product/ic_product_14.png) no-repeat center/cover;
}
.theory .step[data-v-3a11801c] {
  display: flex;
  align-items: flex-start;
  margin-top: 0.41666667rem;
}
.theory .step .item:first-child .middle[data-v-3a11801c] {
  border-radius: 0.08333333rem 0 0 0.08333333rem;
}
.theory .step .item:last-child .middle[data-v-3a11801c] {
  border-radius: 0 0.08333333rem 0.08333333rem 0;
}
.theory .step .item1[data-v-3a11801c] {
  flex-grow: 1;
}
.theory .step .item1 .top[data-v-3a11801c] {
  background: #4e99fd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1875rem;
  width: 1.09375rem;
  height: 0.66145833rem;
}
.theory .step .item1 .top .icon[data-v-3a11801c] {
  height: 0.3125rem;
}
.theory .step .item1 .middle[data-v-3a11801c] {
  background: #deecf6;
  margin-bottom: 0.15625rem;
  width: 100%;
  height: 0.22916667rem;
  position: relative;
}
.theory .step .item1 .middle .icon[data-v-3a11801c] {
  width: 0.29166667rem;
  height: 0.29166667rem;
  background: url(@/asset/product/ic_product_20.png) no-repeat center/contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.125rem;
  color: #4e99fd;
  text-align: center;
  line-height: 0.29166667rem;
}
.theory .step .item1 .bottom[data-v-3a11801c] {
  color: #666;
  font-size: 0.16666667rem;
  text-align: center;
}
.theory .step .item2[data-v-3a11801c] {
  flex-grow: 1;
}
.theory .step .item2 .top[data-v-3a11801c] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1875rem;
  width: 0.76041667rem;
  height: 0.66145833rem;
}
.theory .step .item2 .top .icon[data-v-3a11801c] {
  width: 0.50520833rem;
}
.theory .step .item2 .middle[data-v-3a11801c] {
  background: #deecf6;
  width: 100%;
  height: 0.22916667rem;
}
.display[data-v-3a11801c] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.41666667rem;
}
.display .left[data-v-3a11801c] {
  box-shadow: -0.05208333rem 0.05208333rem 0 #ddd;
  width: 2.96354167rem;
  height: 1.00520833rem;
  padding: 0.33333333rem;
  box-sizing: border-box;
  border-radius: 0.04166667rem;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  margin-right: -0.33333333rem;
  position: relative;
}
.display .left .title[data-v-3a11801c] {
  width: 100%;
  font-size: 0.10416667rem;
  margin-bottom: 0.10416667rem;
  color: #4e99fd;
}
.display .left .brief[data-v-3a11801c] {
  width: 100%;
  font-size: 0.10416667rem;
  color: #666;
}
.display .right[data-v-3a11801c] {
  width: 3.42708333rem;
  height: 2.140625rem;
  background: url(@/asset/product/ic_product_21.webp) no-repeat center/cover;
  box-shadow: -0.05208333rem 0.05208333rem 0 #1e63c1;
  border-radius: 0.04166667rem;
}
.feature[data-v-3a11801c] {
  padding: 0.41666667rem 0;
}
.feature .list[data-v-3a11801c] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.41666667rem;
}
.feature .list .item[data-v-3a11801c] {
  text-align: center;
}
.feature .list .item .icon[data-v-3a11801c] {
  width: 0.33854167rem;
  margin-bottom: 0.02083333rem;
}
.feature .list .item .title[data-v-3a11801c] {
  font-size: 0.125rem;
  color: #3384e8;
  margin-bottom: 0.06770833rem;
}
.feature .list .item .brief[data-v-3a11801c] {
  font-size: 0.09375rem;
  color: #666;
  line-height: 0.125rem;
  margin: 0;
}
.feature .end[data-v-3a11801c] {
  margin-top: 0.20833333rem;
}
.feature .end .item[data-v-3a11801c] {
  display: flex;
  gap: 0.46875rem;
  margin-bottom: 0.41666667rem;
}
.feature .end .item[data-v-3a11801c]:last-child {
  margin-bottom: 0;
}
.feature .end .item[data-v-3a11801c]:nth-child(2n) {
  flex-direction: row-reverse;
}
.feature .end .item:nth-child(2n) .title[data-v-3a11801c] {
  text-align: right;
}
.feature .end .item .txt[data-v-3a11801c] {
  flex: 1;
}
.feature .end .item .txt .title[data-v-3a11801c] {
  font-size: 0.125rem;
  color: #0865aa;
  margin-bottom: 0.15625rem;
}
.feature .end .item .txt .brief[data-v-3a11801c] {
  font-size: 0.09375rem;
  color: #999;
  line-height: 2;
}
.feature .end .item .img[data-v-3a11801c] {
  flex: 1;
}