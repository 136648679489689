.relative[data-v-d81387a2] {
  position: relative;
}
.w-full[data-v-d81387a2] {
  width: 100%;
}
.relative-center[data-v-d81387a2] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-d81387a2] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-d81387a2] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-d81387a2] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-d81387a2] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-d81387a2] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-d81387a2] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-d81387a2] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-d81387a2] {
  justify-content: space-between;
}
.flex[data-v-d81387a2] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-d81387a2] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-d81387a2],
.card-list-no-padding .ci-description[data-v-d81387a2] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-d81387a2] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.partner-container[data-v-d81387a2] {
  padding-top: 3.125rem;
  background: #fff;
  position: relative;
  overflow: hidden;
}
.partner-container .title[data-v-d81387a2] {
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}
.partner-container .title .title-value[data-v-d81387a2]::after {
  display: block;
  content: "";
  width: 100%;
  height: 0.078125rem;
  background: #0f52fb;
  margin-top: 0.03125rem;
}
.partner-container .sub-title[data-v-d81387a2] {
  font-size: .5625rem;
  font-weight: 400;
  color: #999;
  display: flex;
  justify-content: flex-end;
  padding: .8125rem 0 1.5625rem 0;
}
.partner-container .description[data-v-d81387a2] {
  font-size: .75rem;
  font-weight: 500;
  color: #666;
  display: flex;
  justify-content: flex-end;
}
.partner-container .bg-black[data-v-d81387a2] {
  position: absolute;
  top: -0.72916667rem;
  left: 0;
  height: 200%;
  width: 300%;
  z-index: -1;
  transform: translateX(-40%) translateY(-1%) rotate(5deg);
  background: #1a1a1a;
}
.partner-container .partner-list[data-v-d81387a2] {
  min-height: 2.60416667rem;
  margin-top: 1.30208333rem;
  padding-bottom: 0.26041667rem;
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 0.9375rem;
  gap: 0.20833333rem;
}
.partner-container .partner-list .partner-item[data-v-d81387a2] {
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  border: 0.00520833rem solid #0f52fb;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner-container .partner-list .partner-item .logo.hover[data-v-d81387a2] {
  display: none;
}
.partner-container .partner-list .partner-item[data-v-d81387a2]:hover {
  background: #fff;
}
.partner-container .partner-list .partner-item:hover .logo[data-v-d81387a2] {
  display: none;
}
.partner-container .partner-list .partner-item:hover .logo.hover[data-v-d81387a2] {
  display: block;
}
.partner-container .partner-list .partner-item img[data-v-d81387a2] {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.partner-container .partner-list .partner-item[data-v-d81387a2]::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  right: -0.05208333rem;
  top: -0.05208333rem;
  background: #0f52fb;
  z-index: -1;
}
.partner-container .partner-list .partner-item.empty[data-v-d81387a2] {
  background: none;
  border: none;
}
.partner-container .partner-list .partner-item.empty[data-v-d81387a2]::before {
  display: none;
}
.partner-container .more[data-v-d81387a2] {
  background: #0f52fb;
  border-radius: 0.02083333rem 0.02083333rem 0.02083333rem 0.02083333rem;
  padding: 0.05208333rem 0.10416667rem;
  white-space: nowrap;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.125rem;
  font-weight: 400;
  color: #fffdfd;
  cursor: pointer;
  box-sizing: border-box;
}
.partner-container .more img[data-v-d81387a2] {
  width: 0.16666667rem !important;
  height: 0.0625rem !important;
}
@media screen and (max-width: 1440px) {
.partner-container .more[data-v-d81387a2] {
    padding: 0.02604167rem 0.05208333rem;
}
.partner-container .partner-list[data-v-d81387a2] {
    grid-template-columns: repeat(4, 1fr);
}
}
@media screen and (max-width: 1100px) {
.partner-container .more[data-v-d81387a2] {
    padding: 0.02604167rem 0.05208333rem;
}
.partner-container .partner-list[data-v-d81387a2] {
    grid-template-columns: repeat(3, 1fr);
}
}
@media screen and (max-width: 767px) {
.partner-container .title[data-v-d81387a2] {
    font-size: 0.125rem;
}
.partner-container .more[data-v-d81387a2] {
    padding: 0.02604167rem 0.05208333rem;
}
.partner-container .partner-list[data-v-d81387a2] {
    gap: 0.10416667rem;
    margin-top: 0.41666667rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 0.625rem;
    min-height: unset;
    padding-bottom: 0.10416667rem;
}
.partner-container .partner-list .bg-black[data-v-d81387a2] {
    top: -0.26041667rem;
}
.partner-container .more[data-v-d81387a2] {
    padding: 0.05208333rem 0.10416667rem;
    white-space: nowrap;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.0625rem;
    font-weight: 400;
    color: #fffdfd;
    cursor: pointer;
    box-sizing: border-box;
}
.partner-container .more img[data-v-d81387a2] {
    width: 0.078125rem !important;
}
}
@media screen and (max-width: 600px) {
.partner-container .partner-list[data-v-d81387a2] {
    gap: 0.10416667rem;
    margin-top: 0.41666667rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 0.41666667rem;
    min-height: unset;
    padding-bottom: 0.10416667rem;
}
}