.relative[data-v-8fe084b6] {
  position: relative;
}
.w-full[data-v-8fe084b6] {
  width: 100%;
}
.relative-center[data-v-8fe084b6] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-8fe084b6] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-8fe084b6] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-8fe084b6] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-8fe084b6] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-8fe084b6] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-8fe084b6] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-8fe084b6] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-8fe084b6] {
  justify-content: space-between;
}
.flex[data-v-8fe084b6] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-8fe084b6] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-8fe084b6],
.card-list-no-padding .ci-description[data-v-8fe084b6] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-8fe084b6] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.structure[data-v-8fe084b6] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.structure .img[data-v-8fe084b6] {
  display: block;
  margin-top: 0.20833333rem;
}
.feature[data-v-8fe084b6] {
  background: url(@/asset/smart-traffic/ic_traffic_65.png) no-repeat center/cover;
  padding: 0.41666667rem 0 0.20833333rem;
}
.footer-resolution[data-v-8fe084b6] {
  height: 1.58333333rem;
  background-size: cover;
}
.footer-resolution .layout[data-v-8fe084b6] {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.10416667rem;
}
.footer-resolution .f-title[data-v-8fe084b6] {
  font-family: YouSheBiaoTiHei,YouSheBiaoTiHei;
  font-weight: 400;
  font-size: 0.1875rem;
  color: #333;
  margin-top: 0.3125rem;
}
.footer-resolution .f-en[data-v-8fe084b6] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #999;
  margin-top: 0.05208333rem;
}
.footer-resolution .f-sub[data-v-8fe084b6] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #999;
  margin-top: 0.20833333rem;
}
.footer-resolution .ask-btn[data-v-8fe084b6] {
  width: 1.04166667rem;
  height: 0.3125rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  box-shadow: 0.015625rem 0.015625rem 0.04166667rem 0.00520833rem #e8e8e8;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.52083333rem;
}