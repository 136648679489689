.relative[data-v-1753b540] {
  position: relative;
}
.w-full[data-v-1753b540] {
  width: 100%;
}
.relative-center[data-v-1753b540] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-1753b540] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-1753b540] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-1753b540] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-1753b540] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-1753b540] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-1753b540] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-1753b540] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-1753b540] {
  justify-content: space-between;
}
.flex[data-v-1753b540] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-1753b540] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-1753b540],
.card-list-no-padding .ci-description[data-v-1753b540] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-1753b540] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.pin-card[data-v-1753b540] {
  box-sizing: border-box;
  padding: 0.11458333rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 0.08333333rem;
  grid-template-areas: "a b" "a c";
}
.pin-card.single[data-v-1753b540] {
  grid-template-areas: "a b";
}
.pin-card.single .p-sub[data-v-1753b540] {
  align-self: center;
}
.pin-card img[data-v-1753b540] {
  grid-area: a;
  object-fit: scale-down;
}
.pin-card .p-title[data-v-1753b540] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #3384e8;
  align-self: flex-start;
}
.pin-card .p-sub[data-v-1753b540] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.09375rem;
  color: #666;
  align-self: flex-end;
}