.relative[data-v-a47e42a8] {
  position: relative;
}
.w-full[data-v-a47e42a8] {
  width: 100%;
}
.relative-center[data-v-a47e42a8] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-a47e42a8] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-a47e42a8] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-a47e42a8] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-a47e42a8] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-a47e42a8] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-a47e42a8] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-a47e42a8] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-a47e42a8] {
  justify-content: space-between;
}
.flex[data-v-a47e42a8] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-a47e42a8] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-a47e42a8],
.card-list-no-padding .ci-description[data-v-a47e42a8] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-a47e42a8] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
[data-v-a47e42a8] .now-button {
  display: none;
}
.use-bg[data-v-a47e42a8] {
  background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
  background-size: cover;
}
.cd-list[data-v-a47e42a8] {
  display: grid;
  grid-template-columns: repeat(2, 3.22916667rem);
  justify-content: space-between;
  grid-auto-rows: 2.5rem;
  row-gap: 0.20833333rem;
}
.cd-list .cd-item[data-v-a47e42a8] {
  position: relative;
}
.cd-list .cd-item img[data-v-a47e42a8] {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cd-list .cd-item:hover .context[data-v-a47e42a8] {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(78,153,253,.8);
}
.cd-list .cd-item:hover .context .c-description-inline[data-v-a47e42a8] {
  display: none;
}
.cd-list .cd-item:hover .context .c-title[data-v-a47e42a8] {
  color: #fff;
}
.cd-list .cd-item:hover .context .c-description[data-v-a47e42a8] {
  color: #fff;
  display: block;
}
.cd-list .context[data-v-a47e42a8] {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.54166667rem;
  width: 100%;
  overflow: hidden;
  background: hsla(0,0%,100%,.8);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  padding: 0.125rem 0;
  box-sizing: border-box;
  transition: all .2s;
}
.cd-list .context .c-title[data-v-a47e42a8] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #333;
  white-space: nowrap;
  padding: 0 0.125rem;
  box-sizing: border-box;
  transition: all .2s;
}
.cd-list .context .c-description-inline[data-v-a47e42a8],
.cd-list .context .c-description[data-v-a47e42a8] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #666;
  padding: 0 0.125rem;
  box-sizing: border-box;
  margin-top: 0.08333333rem;
  transition: all .2s;
}
.cd-list .context .c-description-inline[data-v-a47e42a8] {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cd-list .context .c-description[data-v-a47e42a8] {
  display: none;
}