.relative[data-v-444bb6f6] {
  position: relative;
}
.w-full[data-v-444bb6f6] {
  width: 100%;
}
.relative-center[data-v-444bb6f6] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-444bb6f6] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-444bb6f6] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-444bb6f6] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-444bb6f6] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-444bb6f6] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-444bb6f6] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-444bb6f6] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-444bb6f6] {
  justify-content: space-between;
}
.flex[data-v-444bb6f6] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-444bb6f6] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-444bb6f6],
.card-list-no-padding .ci-description[data-v-444bb6f6] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-444bb6f6] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.display-list .block-content[data-v-444bb6f6] {
  display: flex;
  flex-direction: column;
  gap: 0.41666667rem;
}
.display-list .dl-item[data-v-444bb6f6] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.41666667rem;
}
.display-list .dl-board[data-v-444bb6f6] {
  object-fit: cover;
  width: 100%;
}
.display-list .dl-title[data-v-444bb6f6] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bold;
  font-size: 0.125rem;
  color: #4e99fd;
}
.display-list .dl-hr[data-v-444bb6f6] {
  width: 0.41666667rem;
  height: 0.02083333rem;
  background: #4e99fd;
  margin-top: 0.08333333rem;
}
.display-list .dl-description[data-v-444bb6f6] {
  margin-top: 0.20833333rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.09375rem;
  color: #666;
  line-height: 0.1875rem;
  white-space: pre-wrap;
}
.display-list .dl-button[data-v-444bb6f6] {
  margin-top: 0.20833333rem;
  width: 1.13020833rem;
  height: 0.31770833rem;
  background: #4e99fd;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  cursor: pointer;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #fffdfd;
  display: flex;
  justify-content: center;
  align-items: center;
}