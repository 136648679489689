.relative[data-v-4ad12b4c] {
  position: relative;
}
.w-full[data-v-4ad12b4c] {
  width: 100%;
}
.relative-center[data-v-4ad12b4c] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.full-center[data-v-4ad12b4c] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-4ad12b4c] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.pt-100px[data-v-4ad12b4c] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-4ad12b4c] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-4ad12b4c] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-4ad12b4c] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-4ad12b4c] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-4ad12b4c] {
  justify-content: space-between;
}
.flex[data-v-4ad12b4c] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-4ad12b4c] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-4ad12b4c],
.card-list-no-padding .ci-description[data-v-4ad12b4c] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast-shadow[data-v-4ad12b4c] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1608);
  z-index: 1000;
}
.footer-container[data-v-4ad12b4c] {
  width: 100%;
  padding-top: 0.10416667rem;
  background-image: url("./assets/footer-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #323537;
}
.footer-container *[data-v-4ad12b4c] {
  cursor: default;
}
.footer-container .grid-box[data-v-4ad12b4c] {
  margin-top: 0.15625rem;
  display: grid;
  gap: 0.26041667rem;
  grid-template-columns: auto auto;
  justify-content: center;
}
.footer-container .left[data-v-4ad12b4c] {
  display: grid;
  gap: 0.20833333rem;
  grid-template-columns: 0.65104167rem 1.53645833rem;
}
.footer-container .left .qrcode-box[data-v-4ad12b4c] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.footer-container .left .qrcode-box .qrcode[data-v-4ad12b4c] {
  width: 0.65104167rem;
}
.footer-container .left .company-info[data-v-4ad12b4c] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-container .left .qr-title[data-v-4ad12b4c] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.09375rem;
  color: #fff;
  margin-top: 0.125rem;
  text-stroke: 0.00520833rem rgba(0,0,0,0);
  text-align: left;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 0.00520833rem rgba(0,0,0,0);
}
.footer-container .left .qr-explain[data-v-4ad12b4c] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: hsla(0,0%,100%,.6);
  margin-top: 0.08333333rem;
  text-stroke: 0.00520833rem rgba(0,0,0,0);
  text-align: left;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 0.00520833rem rgba(0,0,0,0);
}
.footer-container .left .ci-title[data-v-4ad12b4c] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bold;
  font-size: 0.07291667rem;
  color: #fff;
}
.footer-container .left .ci-phone[data-v-4ad12b4c] {
  margin-top: 0.15625rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bolder;
  font-size: 0.125rem;
  color: #4e99fd;
}
.footer-container .left .ci-mail[data-v-4ad12b4c] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.07291667rem;
  color: hsla(0,0%,100%,.6);
  line-height: 0.125rem;
}
.footer-container .right[data-v-4ad12b4c] {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 0.3125rem;
}
.footer-container .right .top-title[data-v-4ad12b4c] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
  cursor: pointer;
}
.footer-container .right .r-children[data-v-4ad12b4c] {
  margin-top: 0.125rem;
  display: flex;
  flex-direction: column;
  gap: 0.08333333rem;
}
.footer-container .right .r-children .r-child[data-v-4ad12b4c] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: hsla(0,0%,100%,.6);
  cursor: pointer;
  transition: color .2s;
}
.footer-container .right .r-children .r-child[data-v-4ad12b4c]:hover {
  color: #4e99fd;
}
.footer-container .copyright-box[data-v-4ad12b4c] {
  width: 100%;
  margin-top: 0.20833333rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.07291667rem;
  color: hsla(0,0%,100%,.4);
  border-top: 0.00520833rem solid hsla(0,0%,100%,.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem 0;
}
.footer-container .copyright-box img[data-v-4ad12b4c] {
  width: 0.10416667rem;
  height: 0.11458333rem;
}
.footer-container .copyright[data-v-4ad12b4c] {
  margin-left: 0.15625rem;
  color: #707070;
  display: inline-flex;
  align-items: center;
  column-gap: 0.02604167rem;
}
.footer-container .copyright[data-v-4ad12b4c]:hover {
  color: gray;
}